import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@mui/material";
import { Link } from "react-router-dom";

function LoginFormActions({
  usernameIsEvaluated,
  action,
  onBackClick,
  onNextClick,
  usernameInput,
  username,
  resendCode,
  setErrors
}) {
  const { t } = useTranslation();
  const [counter, setCounter] = useState(60);

  useEffect(() => {
    setErrors({});
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  const handleResendCode = () => {
    setErrors({});
    resendCode();
    setCounter(60);
  };

  const resendCodeComponent =
    counter > 0 ? (
      <p className="text-muted opacity-75">
        {t("forms.login.resendCodeBtn") + ` `} ({counter})
      </p>
    ) : (
      <Link to="#" onClick={handleResendCode}>
        {t("forms.login.resendCodeBtn")}
      </Link>
    );

  return (
    <div className="form-footer mt-3">
      <div className="d-flex align-items-center justify-content-between w-100">
        <div>
          {usernameIsEvaluated && action?.action === "PASSWORD" && (
            <Link
              to={{
                pathname: "/login/forgot",
                state: { username: username }
              }}
            >
              {t("forms.login.forgotPasswordBtn")}
            </Link>
          )}
          {action?.action === "MFA_CODE" && resendCodeComponent}
        </div>
        <div>
          {usernameIsEvaluated ? (
            <Button
              type="button"
              onClick={onBackClick}
              variant="outlined"
              color="primary"
              size="large"
            >
              {t("forms.login.backBtn")}
            </Button>
          ) : null}

          {!usernameIsEvaluated ? (
            <Button
              type="button"
              onClick={onNextClick}
              disabled={
                !usernameInput.includes("@") || usernameInput.trim() === ""
              }
              variant="contained"
              color="primary"
              size="large"
            >
              {t("forms.login.nextBtn")}
            </Button>
          ) : null}

          {usernameIsEvaluated && action?.action !== "SSO" ? (
            <Button
              className="ms-3"
              variant="contained"
              color="primary"
              size="large"
              type="submit"
            >
              {t("forms.login.submitBtn")}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default LoginFormActions;
