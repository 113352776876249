import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import objectPath from "object-path";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslation } from "react-i18next";

import { appApi } from "modules/auth/api";
import ViewActions from "modules/auth/views/ViewActions";
import { FORM_VALIDATION } from "modules/auth/constants";
import InputField from "./InputField";

function ForgotPasswordForm({ defaultValues }) {
  const { t } = useTranslation();

  //
  // useState hooks
  //
  const [success, setSuccess] = useState(false);
  const [apiError, setApiError] = useState(false);

  //
  // form hooks
  //
  const {
    handleSubmit,
    formState: { errors },
    setError,
    control
  } = useForm({
    defaultValues
  });

  //
  // ref hooks for programmatic form submit
  //
  const submitRef = useRef(null);

  //
  // functions
  //
  const handleFormSubmit = async data => {
    try {
      await appApi({
        url: "/web/password/insecure/forgot",
        method: "POST",
        data
      });

      setSuccess(true);
    } catch (err) {
      const validationErrors = objectPath.get(
        err,
        "response.data.validation_errors"
      );

      if (validationErrors) {
        validationErrors.map(e => {
          return setError(e.property, {
            message: e.message
          });
        });
      } else {
        setApiError(true);
      }
    }
  };

  if (success) {
    return (
      <div className="forgot-password-message">
        <h3 className="d-flex align-items-center justify-content-center">
          {t("forms.forgotPassword.success")}{" "}
          <CheckCircleIcon fontSize={"large"} className="ms-2" />
        </h3>
        <p>{t("forms.forgotPassword.helperText2")}</p>
        <Link to="/login">{t("views.actions.returnToLoginBtn")}</Link>
      </div>
    );
  }

  if (apiError) {
    return (
      <div className="forgot-password-message">
        <h3>Something has gone wrong</h3>
        <p>Please try again later.</p>
        <Link to="/login">{t("views.actions.returnToLoginBtn")}</Link>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <p className="pb-2">{t("forms.forgotPassword.helperText1")}</p>
      <InputField
        name="username"
        control={control}
        label={t("forms.labels.username")}
        size="lg"
        type="text"
        validation={{ ...FORM_VALIDATION.username }}
        isVertical={true}
        errors={errors}
      />
      <div className="link-container">
        <Link to="/login">{t("views.actions.returnToLoginBtn")}</Link>
        <div className="ms-2">
          <ViewActions submitOnly submitRef={submitRef} />
        </div>
      </div>

      <input ref={submitRef} type="submit" className="hidden" />
    </form>
  );
}

export default ForgotPasswordForm;
