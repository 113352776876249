import React from "react";
import { useTranslation } from "react-i18next";

function PasswordRules() {
  const { t } = useTranslation();

  return (
    <div className="password-rules-container">
      <p>
        <strong>{t("components.passwordRules.header")}</strong>
      </p>
      <p>{t("components.passwordRules.mustHave")}</p>
      {}
      <ul>
        <li>{t("components.passwordRules.rule1")}</li>
        <li>{t("components.passwordRules.rule2")}</li>
        <li>{t("components.passwordRules.rule3")}</li>
      </ul>
    </div>
  );
}

export default PasswordRules;
