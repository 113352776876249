import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Box } from "@mui/material";

import { ReactComponent as LinkArrow } from "modules/auth/assets/LinkArrow.svg";
import footerLogoEN from "modules/auth/assets/AdvanceLogoWhiteEN.svg";
import footerLogoFR from "modules/auth/assets/AdvanceLogoWhiteFR.svg";
import { FOOTER_LINKS } from "modules/auth/constants";

function AppFooter() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const inApp =
    !history.location.pathname.includes("login") &&
    !history.location.pathname.includes("logout");

  const language = i18n.language;
  const logo = language === "fr" ? footerLogoFR : footerLogoEN;

  if (inApp) {
    return null;
  }

  const contents = [
    { id: "about" },
    { id: "solutions" },
    { id: "news" },
    { id: "contact" },
    {
      id: "discover"
    },
    {
      id: "login",
      showArrow: true
    },
    { id: "careers" },
    { id: "adSpecs" },
    { id: "terms", showArrow: true },
    {
      id: "accessibility",
      showArrow: true
    },
    {
      id: "privacy",
      showArrow: true
    }
  ];

  const scrollToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  return (
    <StyledFooter>
      <Box px={10} className="d-flex flex-column">
        <div className="row">
          <div className="col-md-3 col-12 mb-5">
            <img alt="" src={logo} height="42" />
            <div className="d-flex flex-row mt-5">
              <AnchorTag
                className="me-4"
                active={language === "en"}
                href="/login"
              >
                EN
              </AnchorTag>
              |
              <AnchorTag
                className="ms-4"
                active={language === "fr"}
                href="/login?lng=fr"
              >
                FR
              </AnchorTag>
            </div>
          </div>

          <div className="col-md-7 col-12 mb-5">
            <div className="row">
              {contents.map(content => (
                <div className="col-md-4 col-6 mb-3" key={content.id}>
                  <AnchorTag href={FOOTER_LINKS[content.id][language]}>
                    <Trans
                      i18nKey={`layout.footer.${content.id}`}
                      components={{ sup: <sup /> }}
                    />
                    {content.showArrow && <LinkArrow />}
                  </AnchorTag>
                </div>
              ))}
            </div>
          </div>

          <div
            className="col-md-2 col-12 d-flex justify-content-end"
            style={{ cursor: "pointer", height: "18px" }}
            onClick={() => scrollToTop()}
          >
            <h4 className="me-2">{t("layout.footer.backToTopBtn")}</h4>
            <div className="d-flex justify-content-center align-items-center">
              <ArrowUpwardIcon fontSize="large" />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-7 offset-md-3 col-12 text-start">
            {t("layout.footer.rights")}
          </div>
        </div>
      </Box>
    </StyledFooter>
  );
}

const StyledFooter = styled.footer`
  background-color: ${props => props.theme.new_charcoal};
  color: #fff;
  font-size: 14px;
  padding: 48px 0px 24px 0px;
`;

const AnchorTag = styled.a`
  color: white;
  font-size: 16px;
  text-decoration: none;
  transition: all 0.2s ease-in;
  &:hover {
    color: gray;
  }
  text-decoration: ${props => props.active && "underline"};
`;

export default AppFooter;
