import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./i18n";

console.log(process.env.REACT_APP_BUILD_ID);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
