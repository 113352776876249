import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@loblaw-media/icons/fontawesome/pro-solid-svg-icons/faSpinner";

function Loading({ message, containerClass = "loading-container" }) {
  const { t } = useTranslation();

  return (
    <div className={containerClass}>
      <h2 className="mb-3">{message || t("components.loading.message")}</h2>
      <FontAwesomeIcon
        className="spinner-icon"
        icon={faSpinner}
        spin
        size="3x"
      />
    </div>
  );
}

export default Loading;
