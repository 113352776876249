import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

import { StyledPageOverlay } from "modules/auth/components-styled";

function PageOverlay({
  messageTitle,
  messageBody,
  content = null,
  showActions = true
}) {
  const history = useHistory();
  const { t } = useTranslation();

  //
  // functions
  //
  const handleRefresh = () => {
    window.location.reload();
  };

  const handleLogout = () => {
    history.push("/logout");
  };

  return (
    <StyledPageOverlay>
      <h2>{messageTitle || t("components.pageOverlay.messageTitle")}</h2>
      {messageBody === "" ? null : (
        <p>{messageBody || t("components.pageOverlay.messageBody")}</p>
      )}
      {content}
      {showActions && (
        <div className="d-flex mt-3">
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleRefresh}
          >
            {t("components.pageOverlay.refreshBtn")}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            className="ms-3"
            onClick={handleLogout}
          >
            {t("common.logoutBtn")}
          </Button>
        </div>
      )}
    </StyledPageOverlay>
  );
}

export default PageOverlay;
