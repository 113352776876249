import React, { useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import InputField from "../InputField";
import { FORM_VALIDATION } from "modules/auth/constants";

function MFAForm(props) {
  const { errors, setErrors, control } = props;
  const { t } = useTranslation();

  useEffect(() => {
    setErrors({});
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div>
        <p>{t("forms.mfa.helperText")}</p>
      </div>
      <StyledContainer>
        <InputField
          name="code"
          label={<b>{t("forms.labels.authCode")}</b>}
          control={control}
          size="lg"
          type="number"
          validation={{ ...FORM_VALIDATION.code }}
          isVertical={true}
          errors={errors}
        />
      </StyledContainer>
    </>
  );
}

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export default MFAForm;
