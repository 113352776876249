import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";

import enTranslation from "./locales/en/translation.json";
import frTranslation from "./locales/fr/translation.json";

i18n
  .use(LanguageDetector) // detect user language
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslation },
      fr: { translation: frTranslation }
    },
    fallbackLng: "en",
    debug: true,
    detection: {
      order: ["querystring"],
      lookupQueryString: "lng"
    },
    supportedLngs: ["en", "fr"], // restrict to English & French
    nonExplicitSupportedLngs: true, // treat "fr-CA" as "fr"
    interpolation: {
      escapeValue: false
    }
  });

console.log("detected language: ", i18n.language);

export default i18n;
