import styled from "styled-components";

const StyledPageOverlay = styled.div`
  flex-grow: 1;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a {
    color: ${props => props.theme.purple_5};
  }

  h2 {
    color: ${props => props.theme.charcoal};
  }

  p {
    ${props => props.pmb && `margin-bottom: ${props.pmb}px;`}
    color: ${props => props.theme.charcoal};
  }
`;

export default StyledPageOverlay;
