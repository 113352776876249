import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@loblaw-media/icons/fontawesome/pro-solid-svg-icons/faSpinner";
import { useTranslation } from "react-i18next";

import { StyledPageOverlay } from "modules/auth/components-styled";

function RedirectToLogout() {
  const { t } = useTranslation();

  //
  // useEffect hooks
  //
  useEffect(() => {
    setTimeout(() => (window.location.href = "/logout"), 3000);
  }, []);

  return (
    <StyledPageOverlay pmb={50}>
      <h3>{t("views.authenticator.successText")}</h3>
      <p>{t("views.authenticator.helperText3")}</p>
      <FontAwesomeIcon icon={faSpinner} spin size="3x" />
    </StyledPageOverlay>
  );
}

export default RedirectToLogout;
