import React, { useContext, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Context as UserContext } from "modules/auth/context/UserContext";
import { Context as FormContext } from "modules/auth/context/FormContext";
import Loading from "modules/auth/components/Loading";
import ViewActions from "../ViewActions";
import UpdateEmailForm from "modules/auth/forms/UpdateEmailForm";
import FormViewWrapper from "modules/auth/components/FormViewWrapper";

function UpdateEmailViewComponent() {
  const { t } = useTranslation();

  //
  // context hooks
  //
  const {
    state: { user }
  } = useContext(UserContext);

  //
  // ref hooks
  //
  const submitRef = useRef(null);

  const userName = `${user?.first_name} ${user?.last_name}`;

  //
  // constants
  //
  const customActions = (
    <Link to="/profile">
      <Button variant="outlined" color="primary" size="medium">
        {t("views.actions.returnToProfileBtn")}
      </Button>
    </Link>
  );

  const header = (
    <>
      <StyledH2>{t("views.titles.updateEmail", { name: userName })}</StyledH2>
      <ViewActions
        submitOnly
        customEditingActions={customActions}
        submitRef={submitRef}
      />
    </>
  );

  return (
    <>
      {user ? (
        <FormViewWrapper
          viewHeader={header}
          form={
            <UpdateEmailForm
              submitRef={submitRef}
              defaultValues={{ email: user.email }}
            />
          }
        />
      ) : (
        <Loading />
      )}
    </>
  );
}

const UpdateEmailView = () => (
  <FormContext.Provider value={{ state: { edit: true } }}>
    <UpdateEmailViewComponent />
  </FormContext.Provider>
);

const StyledH2 = styled.h2`
  color: ${props => props.theme.charcoal};
  font-weight: 700;
`;

export default UpdateEmailView;
