import React from "react";
import styled from "styled-components";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Button, Card } from "@mui/material";
import { useTranslation } from "react-i18next";

import { TILE_INFO_MAP } from "modules/auth/constants";
import { ReactComponent as LoopPlatformIcon } from "modules/auth/assets/LoopPlatformIcon.svg";
import { ReactComponent as InsightsPlatformIcon } from "modules/auth/assets/shopper_insights.svg";
import TileLink from "./TileLink";

const AppTile = ({ data, isHmp = false, isRmip = false }) => {
  const { identifier, url, external } = data;
  const { t } = useTranslation();

  if (isHmp) {
    return (
      <StyledCard isHmp={isHmp}>
        <div className="d-flex flex-row" style={{ height: "100%" }}>
          <div className="card-content pe-4">
            <div className="content">
              <TileLink app={{ url, external }}>
                <h2 className="fw-bold">{TILE_INFO_MAP[identifier].name}</h2>
              </TileLink>
              <p className="pt-2">{TILE_INFO_MAP[identifier].description}</p>
            </div>
            <TileLink app={{ url, external }}>
              <Button variant="contained" color="primary" size="large">
                {TILE_INFO_MAP[identifier].button}{" "}
                <ArrowForwardIcon sx={{ fontSize: 26 }} />
              </Button>
            </TileLink>
          </div>
          <div className="ps-4">
            <LoopPlatformIcon aria-label={t("altText.hmp")} />
          </div>
        </div>
      </StyledCard>
    );
  }

  if (isRmip) {
    return (
      <StyledCard isRmip={isRmip}>
        <div className="d-flex flex-row" style={{ height: "100%" }}>
          <div className="card-content pe-4">
            <div className="content">
              <TileLink app={{ url, external }}>
                <h2 className="fw-bold">{TILE_INFO_MAP[identifier].name}</h2>
              </TileLink>
              <p className="pt-2">{TILE_INFO_MAP[identifier].description}</p>
            </div>
            <TileLink app={{ url, external }}>
              <Button variant="contained" color="primary" size="medium">
                {TILE_INFO_MAP[identifier].button}{" "}
                <ArrowForwardIcon sx={{ fontSize: 26 }} />
              </Button>
            </TileLink>
          </div>
          <div className="ps-4">
            <InsightsPlatformIcon aria-label={t("altText.rmip")} />
          </div>
        </div>
      </StyledCard>
    );
  }

  return (
    <StyledCard isHmp={isHmp}>
      <div className="card-content">
        <div className="content">
          <TileLink app={{ url, external }}>
            <h2 className="fw-bold">{TILE_INFO_MAP[identifier].name}</h2>
          </TileLink>
          <p className="pt-2">{TILE_INFO_MAP[identifier].description}</p>
        </div>
        <TileLink app={{ url, external }}>
          <Button variant="outlined" color="primary" size="small">
            <ArrowForwardIcon sx={{ fontSize: 26 }} />
          </Button>
        </TileLink>
      </div>
    </StyledCard>
  );
};

const StyledCard = styled(Card).withConfig({
  shouldForwardProp: prop => prop !== "isHmp" && prop !== "isRmip"
})`
  height: 328px;
  width: ${props =>
    props.isHmp || props.isRmip ? "calc(2 * 330px + 16px)" : "330px"};
  padding: 36px;
  p {
    color: ${props => props.theme.charcoal};
  }

  h2 {
    color: ${props => props.theme.charcoal};

    :hover {
      text-decoration: underline;
    }
  }

  ${props =>
    (props.isHmp || props.isRmip) &&
    `
    grid-column: span 2;
  `}

  .card-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .content {
    flex: 1;
  }
`;

export default AppTile;
