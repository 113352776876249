import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import objectPath from "object-path";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { appApi } from "modules/auth/api";
import { Context as UserContext } from "modules/auth/context/UserContext";
import { Context as FormContext } from "modules/auth/context/FormContext";
import InputField from "../InputField";

function ProfileForm({ defaultValues, submitRef }) {
  const { t } = useTranslation();

  //
  // form hooks
  //
  const {
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    reset,
    control
  } = useForm({
    defaultValues
  });

  //
  // context hooks
  //
  const { setUser } = useContext(UserContext);

  const {
    setEdit,
    state: { edit }
  } = useContext(FormContext);

  //
  // useEffect hooks
  //
  useEffect(() => {
    if (!edit) {
      clearErrors();
      reset(defaultValues);
    }
  }, [edit, clearErrors, reset, defaultValues]);

  //
  // functions
  //
  const handleFormSubmit = async data => {
    clearErrors();
    const { first_name, last_name } = data;
    try {
      const response = await appApi({
        url: "/web/profile",
        method: "PATCH",
        data: {
          first_name,
          last_name
        }
      });

      setUser(response.data.content);
      setEdit(false);
      toast.success(t("forms.toasts.profileUpdateSuccess"));
    } catch (err) {
      const validationErrors = objectPath.get(
        err,
        "response.data.validation_errors"
      );

      if (validationErrors) {
        validationErrors.map(e => {
          return setError(e.property, {
            message: e.message
          });
        });
      } else {
        throw err;
      }
    }
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(handleFormSubmit)}>
      <InputField
        name="username"
        control={control}
        required
        label={t("forms.labels.username")}
        size="lg"
        type="text"
        readOnly={true}
        isVertical={true}
        errors={errors}
      />
      <InputField
        name="email"
        control={control}
        required
        label={t("forms.labels.email")}
        size="lg"
        type="text"
        readOnly={true}
        isVertical={true}
        errors={errors}
      />
      <InputField
        name="first_name"
        control={control}
        required
        label={t("forms.labels.firstName")}
        size="lg"
        type="text"
        readOnly={!edit}
        isVertical={true}
        errors={errors}
      />
      <InputField
        name="last_name"
        control={control}
        required
        label={t("forms.labels.lastName")}
        size="lg"
        type="text"
        readOnly={!edit}
        isVertical={true}
        errors={errors}
      />
      <input ref={submitRef} type="submit" className="hidden" />
    </form>
  );
}

export default ProfileForm;
