import caLogo from "modules/auth/assets/icon-standaloneca.svg";
import accountsLogo from "modules/auth/assets/icon-user-accounts.png";
import profileLogo from "modules/auth/assets/icon-my-account.png";
import dspLogo from "modules/auth/assets/icon-dsp.png";
import cmLogo from "modules/auth/assets/icon-dsp.png";
import utmLogo from "modules/auth/assets/icon-utm.png";
import rmpLogo from "modules/auth/assets/icon-rmp.png";
import audienceLogo from "modules/auth/assets/icon-audience-planning.png";
import adbuilderLogo from "modules/auth/assets/icon-adbuilder.png";
import hmpbannerLogo from "modules/auth/assets/hmpbanner.svg";
import rmipLogo from "modules/auth/assets/shopper_insights.svg";
import i18next from "i18n";

const EMAIL_REGEX = /\S+@\S+\.\S+/;

export const TILE_INFO_MAP = {
  analytics: {
    logo: caLogo,
    name: i18next.t("constants.tileName.analytics"),
    description: i18next.t("constants.tileDescription.analytics")
  },
  eyecontrol: {
    logo: dspLogo
  },
  cm: {
    logo: cmLogo,
    name: i18next.t("constants.tileName.cm"),
    description: i18next.t("constants.tileDescription.cm")
  },
  profile: {
    logo: profileLogo,
    name: i18next.t("constants.tileName.profile"),
    description: i18next.t("constants.tileDescription.profile")
  },
  accounts: {
    logo: accountsLogo,
    name: i18next.t("constants.tileName.accounts"),
    description: i18next.t("constants.tileDescription.accounts")
  },
  utm: {
    logo: utmLogo,
    name: i18next.t("constants.tileName.utm"),
    description: i18next.t("constants.tileDescription.utm")
  },
  rmp: {
    logo: rmpLogo,
    name: i18next.t("constants.tileName.rmp"),
    description: i18next.t("constants.tileDescription.rmp")
  },
  hmp: {
    logo: hmpbannerLogo,
    name: i18next.t("constants.tileName.hmp"),
    description: i18next.t("constants.tileDescription.hmp"),
    button: i18next.t("constants.tileBtn.hmp")
  },
  galapagos: {
    logo: audienceLogo,
    name: i18next.t("constants.tileName.galapagos"),
    description: i18next.t("constants.tileDescription.galapagos")
  },
  "ad-builder": {
    logo: adbuilderLogo,
    name: i18next.t("constants.tileName.adBuilder"),
    description: i18next.t("constants.tileDescription.adBuilder")
  },
  rmip: {
    logo: rmipLogo,
    name: i18next.t("constants.tileName.rmip"),
    description: i18next.t("constants.tileDescription.rmip"),
    button: i18next.t("constants.tileBtn.rmip")
  }
};

export const FORM_VALIDATION = {
  username: {
    required: {
      value: true,
      message: i18next.t("constants.formValidation.required")
    },
    pattern: {
      value: EMAIL_REGEX,
      message: i18next.t("constants.formValidation.validEmail")
    },
    minLength: {
      value: 3,
      message: i18next.t("constants.formValidation.minLength")
    }
  },
  password: {
    required: {
      value: true,
      message: i18next.t("constants.formValidation.required")
    },
    minLength: {
      value: 3,
      message: i18next.t("constants.formValidation.minLength")
    }
  },
  code: {
    required: {
      value: true,
      message: i18next.t("constants.formValidation.required")
    },
    minLength: {
      value: 6,
      message: i18next.t("constants.formValidation.codeLength")
    },
    maxLength: {
      value: 6,
      message: i18next.t("constants.formValidation.codeLength")
    }
  }
};

export const LOGIN_VIEW_MESSAGES = {
  blocked: i18next.t("constants.loginViewMessages.blocked"),
  logout: i18next.t("constants.loginViewMessages.logout"),
  disabled: i18next.t("constants.loginViewMessages.disabled"),
  unavailable: i18next.t("constants.loginViewMessages.unavailable"),
  error: i18next.t("constants.loginViewMessages.error"),
  unknown: i18next.t("constants.loginViewMessages.unknown"),
  pending: i18next.t("constants.loginViewMessages.pending")
};

export const MFA_VIEW_MESSAGES = {
  error: i18next.t("constants.mfaViewMessages.error"),
  blocked: i18next.t("constants.mfaViewMessages.blocked")
};

export const NEW_PASSWORD_COPY = {
  register: {
    header: i18next.t("constants.newPasswordView.register.header"),
    successHeader: i18next.t(
      "constants.newPasswordView.register.successHeader"
    ),
    success: i18next.t("constants.newPasswordView.register.success")
  },
  reset: {
    header: i18next.t("constants.newPasswordView.reset.header"),
    successHeader: i18next.t("constants.newPasswordView.reset.successHeader"),
    success: i18next.t("constants.newPasswordView.reset.success")
  },
  noKey: i18next.t("constants.newPasswordView.noKey")
};

export const LOGIN_VIEW_LINKS = {
  advance: {
    en: "https://www.loblawadvance.ca"
  },
  discover: {
    en: "https://www.loblawadvance.ca/discover-mediaaisle",
    fr: "https://www.loblawadvance.ca/decouvrez-mediaaisle"
  },
  contact: {
    en: "https://www.loblawadvance.ca/contact-us",
    fr: "https://www.loblawadvance.ca/nous-contacter"
  }
};

export const FOOTER_LINKS = {
  about: {
    en: "https://www.loblawadvance.ca/about",
    fr: "https://www.loblawadvance.ca/sur"
  },
  contact: {
    en: "https://www.loblawadvance.ca/contact-us",
    fr: "https://www.loblawadvance.ca/nous-contacter"
  },
  careers: {
    en: "https://www.loblawadvance.ca/job-postings",
    fr: "https://www.loblawadvance.ca/job-postings-fr"
  },
  news: {
    en: "https://www.loblawadvance.ca/insights",
    fr: "https://www.loblawadvance.ca/connaissances"
  },
  adSpecs: {
    en: "https://www.loblawadvance.ca/ad-specs",
    fr: "https://www.loblawadvance.ca/specifications-publicitaires"
  },
  solutions: {
    en: "https://www.loblawadvance.ca/solutions",
    fr: "https://www.loblawadvance.ca/solutions-fr"
  },
  discover: {
    en: "https://www.loblawadvance.ca/discover-mediaaisle",
    fr: "https://www.loblawadvance.ca/decouvrez-mediaaisle"
  },
  login: {
    en: "#",
    fr: "#"
  },
  terms: {
    en: "https://www.loblaw.ca/en/legal",
    fr: "https://www.loblaw.ca/fr/legal"
  },
  accessibility: {
    en: "https://www.loblaw.ca/en/accessibility/",
    fr: "https://www.loblaw.ca/fr/accessibility/"
  },
  privacy: {
    en: "https://www.loblaw.ca/en/privacy-policy/",
    fr: "https://www.loblaw.ca/fr/privacy-policy/"
  }
};
