import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import objectPath from "object-path";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import InputField from "../InputField";
import { appApi } from "modules/auth/api";

function UpdatePasswordForm({ submitRef }) {
  const { t } = useTranslation();

  //
  // form hooks
  //
  const {
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    watch,
    control
  } = useForm();

  //
  // useRef hooks
  //
  const password = useRef({});
  password.current = watch("new_password", "");

  //
  // history hooks
  //
  const history = useHistory();

  //
  // functions
  //
  const handleFormSubmit = async ({ confirm_password, ...data }) => {
    clearErrors();

    try {
      await appApi({
        url: "/web/password/update",
        method: "POST",
        data
      });

      history.push("/profile");
      toast.success(t("forms.toasts.passwordUpdateSuccess"));
    } catch (err) {
      const validationErrors = objectPath.get(
        err,
        "response.data.validation_errors"
      );

      if (validationErrors) {
        validationErrors.map(e => {
          return setError(e.property, {
            message: e.message
          });
        });
      } else {
        throw err;
      }
    }
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(handleFormSubmit)}>
      <InputField
        name="old_password"
        label={t("forms.labels.oldPassword")}
        type="password"
        control={control}
        size="lg"
        isVertical={true}
        errors={errors}
      />
      <InputField
        name="new_password"
        label={t("forms.labels.newPassword")}
        type="password"
        control={control}
        size="lg"
        isVertical={true}
        errors={errors}
      />
      <InputField
        name="confirm_password"
        label={t("forms.labels.confirmPassword")}
        type="password"
        control={control}
        validation={{
          validate: value =>
            value === password.current || t("forms.errors.passwordMatch")
        }}
        size="lg"
        isVertical={true}
        errors={errors}
      />
      <input ref={submitRef} type="submit" className="hidden" />
    </form>
  );
}

export default UpdatePasswordForm;
