import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Context as UserContext } from "modules/auth/context/UserContext";
import { Context as FormContext } from "modules/auth/context/FormContext";
import FormViewWrapper from "modules/auth/components/FormViewWrapper";
import AuthenticatorForm from "modules/auth/forms/AuthenticatorForm";
import Loading from "modules/auth/components/Loading";
import { appApi } from "modules/auth/api";
import PageOverlay from "modules/auth/components/PageOverlay";
import RedirectToLogout from "./RedirectToLogout";

function AuthenticatorViewComponent() {
  const { t } = useTranslation();

  //
  // context hooks
  //
  const {
    state: { user }
  } = useContext(UserContext);

  //
  // state hooks
  //
  const [authenticatorResponse, setAuthenticatorResponse] = useState(null);
  const [error, setError] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(null);

  //
  // ref hooks for programmatic form submit
  //
  const submitRef = useRef(null);

  const userName = `${user?.first_name} ${user?.last_name}`;

  //
  // functions
  //
  const fetchAuthenticatorStatus = useCallback(async () => {
    try {
      const response = await appApi.get("/web/mfa/totp");

      setAuthenticatorResponse(response.data.content);
    } catch (e) {
      setError(e);
    }
  }, [setError, setAuthenticatorResponse]);

  //
  // useEffect hooks
  //
  useEffect(() => {
    fetchAuthenticatorStatus();
  }, [fetchAuthenticatorStatus]);

  let header = (
    <StyledH2>
      {authenticatorResponse?.enabled
        ? t("views.titles.auth_isEnabled", { name: userName })
        : t("views.titles.auth_isNotEnabled", { name: userName })}
    </StyledH2>
  );

  let backAction = (
    <Link to="/profile">
      <Button variant="outlined" color="primary" size="medium">
        Return to Profile
      </Button>
    </Link>
  );
  if (!authenticatorResponse?.enabled) {
    header = (
      <>
        {header}
        {backAction}
      </>
    );
  }

  const viewPrepend = (
    <>
      <PrependText>{t("views.authenticator.helperText1")}</PrependText>
      <PrependText mb={50}>{t("views.authenticator.helperText2")}</PrependText>
    </>
  );

  if (error) {
    return <PageOverlay />;
  }

  if (submitSuccess) {
    return <RedirectToLogout />;
  }

  return (
    <>
      {user && authenticatorResponse ? (
        <FormViewWrapper
          viewHeader={header}
          form={
            <AuthenticatorForm
              submitRef={submitRef}
              authenticatorResponse={authenticatorResponse}
              fetchAuthenticatorStatus={fetchAuthenticatorStatus}
              setSubmitSuccess={setSubmitSuccess}
            />
          }
          viewPrepend={viewPrepend}
        />
      ) : (
        <Loading />
      )}
    </>
  );
}

const AuthenticatorView = () => (
  <FormContext.Provider value={{ state: { edit: true } }}>
    <AuthenticatorViewComponent />
  </FormContext.Provider>
);

const StyledH2 = styled.h2`
  color: ${props => props.theme.charcoal};
  font-weight: 700;
`;

export const PrependText = styled.p`
  line-height: 3rem;
  margin-bottom: ${props => (props.mb ? `${props.mb}px` : "1em")};
  color: ${props => props.theme.charcoal};
`;

export default AuthenticatorView;
