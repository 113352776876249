import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@loblaw-media/icons/fontawesome/pro-regular-svg-icons/faCircle";
import { faCheckCircle } from "@loblaw-media/icons/fontawesome/pro-solid-svg-icons/faCheckCircle";

function IdentificationList(props) {
  const { t } = useTranslation();
  const {
    identifications,
    identificationOrg,
    primaryOrg,
    setIdentificationOrg
  } = props;

  const handleClick = (event, value) => {
    event.preventDefault();
    setIdentificationOrg(value);
  };

  return (
    <>
      <h4>{t("components.identificationList.header")}</h4>
      <StyledTable>
        <thead>
          <tr>
            <StyledTableHeading as="th">
              {t("components.identificationList.cols.select")}
            </StyledTableHeading>
            <StyledTableHeading as="th">
              {t("components.identificationList.cols.id")}
            </StyledTableHeading>
            <StyledTableHeading as="th">
              {t("components.identificationList.cols.name")}
            </StyledTableHeading>
            <StyledTableHeading as="th">
              {t("components.identificationList.cols.accountType")}
            </StyledTableHeading>
          </tr>
        </thead>
        <tbody>
          {primaryOrg ? (
            <tr>
              <StyledTableCells>
                {identificationOrg ? (
                  <button
                    className="btn btn-outline-primary"
                    onClick={e => handleClick(e, null)}
                  >
                    <FontAwesomeIcon icon={faCircle} />
                  </button>
                ) : (
                  <button
                    className="btn btn-outline-primary"
                    onClick={e => handleClick(e, null)}
                  >
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </button>
                )}
              </StyledTableCells>
              <StyledTableCells>{primaryOrg.org_id}</StyledTableCells>
              <StyledTableCells>{primaryOrg.org_name}</StyledTableCells>
              <StyledTableCells>{primaryOrg.type}</StyledTableCells>
            </tr>
          ) : null}
          {identifications?.map(identification => (
            <tr key={identification.org_id}>
              <StyledTableCells>
                {identificationOrg === identification.org_id ? (
                  <button
                    className="btn btn-outline-primary"
                    onClick={e => handleClick(e, null)}
                  >
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </button>
                ) : (
                  <button
                    className="btn btn-outline-primary"
                    onClick={e => handleClick(e, identification.org_id)}
                  >
                    <FontAwesomeIcon icon={faCircle} />
                  </button>
                )}
              </StyledTableCells>
              <StyledTableCells>{identification.org_id}</StyledTableCells>
              <StyledTableCells>{identification.org_name}</StyledTableCells>
              <StyledTableCells>{identification.type}</StyledTableCells>
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </>
  );
}
const StyledTable = styled.table`
  border-collapse: collapse;
`;
const StyledTableCells = styled.td`
  padding: 10px;
`;
const StyledTableHeading = styled.th`
  text-align: left;
  background-color: grey;
  color: white;
  padding: 10px;
`;

export default IdentificationList;
